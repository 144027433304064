<template>
  <div>
    <HeaderPanel
      title="Category"
      :hasSearch="false"
      :hasFilter="false"
      :hasDropdown="false"
    />
    <div v-if="isLoadingData">
      <OtherLoading />
    </div>
    <div v-else class="mt-3">
      <div class="no-gutters bg-white p-2 p-lg-3">
        <div>
          <b-row>
            <b-col md="4" class="pr-0">
              <CategoryList
                :items="items"
                :activeItem="activeItem"
                :parentList="parentList"
                @handleSetMainCategoryData="setFormMainCategory"
                @getForUpdateCategoryData="getForUpdateCategoryData"
                @handleSetSubCategoryData="createSubCategory"
                @handleGetCategoryData="getCategoryData"
              />
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col sm="12" md="6">
                  <InputText
                    textFloat="Name"
                    placeholder="Name"
                    type="text"
                    name="name"
                    isRequired
                    v-model="form.name"
                    :v="$v.form.name"
                    :oninput="'javascript: if (this.value.length > 50) this.value = this.value.slice(0, 50);'"
                  />
                </b-col>
                <b-col sm="12" md="6">
                  <InputText
                    textFloat="Sort Order"
                    placeholder="Sort Order"
                    type="number"
                    name="sortorder"
                    v-model="form.sort_order"
                  />
                </b-col>

                <b-col sm="12" md="6">
                  <InputText
                    textFloat="Ref."
                    placeholder="Ref."
                    type="number"
                    name="ref"
                    v-model="form.ah_id"
                  />
                </b-col>
                <b-col sm="12" md="6" v-if="isSubCategory">
                  <label class="main-label"> Parent Category </label>

                  <b-form-select
                    v-model="subForm.active_item"
                    class="mb-3"
                    disabled
                  >
                    <b-form-select-option :value="null" disabled>
                      Please select an option
                    </b-form-select-option>
                    <template v-for="item of items">
                      <b-form-select-option
                        :value="`level-1-id-${item.id}`"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </b-form-select-option>
                      <template v-for="item_2 of item.category_2">
                        <b-form-select-option
                          :value="`level-2-id-${item_2.id}`"
                          :key="'child-2-' + item_2.id"
                        >
                          <span style="color: red"> {{ item.name }}</span> >
                          {{ item_2.name }}
                        </b-form-select-option>

                        <template v-for="item_3 of item_2.category_3">
                          <b-form-select-option
                            :key="'child-3-' + item_3.id"
                            :value="`level-3-id-${item_3.id}`"
                          >
                            <b>{{ item.name }}</b> > <b>{{ item_2.name }}</b> >
                            {{ item_3.name }}
                          </b-form-select-option>
                          <!-- </b-form-select-option-group> -->

                          <b-form-select-option
                            :value="`level-4-id-${item_4.id}`"
                            :key="'child-4-' + item_4.id"
                            v-for="item_4 of item_3.category_4"
                          >
                            <b>{{ item.name }}</b> > <b>{{ item_2.name }}</b> >
                            {{ item_3.name }} > {{ item_4.name }}
                          </b-form-select-option>
                        </template>
                      </template>
                      <b-form-select-option
                        :value="null"
                        disabled
                        :key="'divider' + item.id"
                      >
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <div class="break-normal"></div>
                <b-col sm="12" md="6"> </b-col>
                <b-col
                  sm="12"
                  md="6"
                  class="text-right"
                  v-if="categoryLevel == 4"
                >
                  <b-btn variant="custom-link" @click="deleteCategory()">
                    Delete
                  </b-btn>
                </b-col>

                <b-col
                  md="12"
                  v-if="
                    categoryLevel == 4 || (categoryLevel == 3 && !notUpdate)
                  "
                >
                  <div class="mt-3 panelborder">
                    <div class="title-tabs">Product List</div>
                    <div class="p-3 no-gutters bg-white">
                      <ProductList
                        ref="ProductList"
                        :productList="productList"
                        :idList="IdList"
                        :rows="rows"
                        :isBusy="isBusy"
                        @pagination="pagination"
                        v-on:setProductID="setProductID"
                        @hanndleChangePerpage="hanndleChangePerpage"
                        @handleSearchProductList="handleSearchProduct"
                        @productFreeze="(val) => (productFreeze = val)"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <FooterAction @submit="submit()" routePath="/extrapoint" />
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";

import CategoryList from "./components/CategoryList";

import ProductList from "./components/ProductList";
import PreviewBox from "@/components/PreviewBox.vue";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "ProductCategory",
  components: {
    CategoryList,
    ProductList,
    UploadFile,
    PreviewBox,
    OtherLoading,
  },
  data() {
    return {
      acceptType: "image/jpeg, image/png",
      isBusy: false,
      isProductInCategory: true,
      activeItem: "",
      isEdit: false,
      isLoadingData: false,
      isDisable: false,
      isLoadingImage: false,
      isSubCategory: false,
      coverImgType: 1,
      showPreview: "",
      rows: 0,
      productList: [],
      IdList: [],
      parentList: [],
      categorys: [],
      showing: 1,
      showingTo: 5,
      items: {},
      noPleaseSelect: true,
      filterGetcategory: {
        search: "",
        includeSubcategory: false,
      },
      productFreeze: [],
      newEditSelectProduct: [],
      filter: {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: 0,
        ah2: 0,
        ah3: 0,
        ah4: 0,
        promotion_id: this.$route.params.id,
        add_product_id: [],
        delete_product_id: [],
      },
      filterGetProduct: {
        search: "",
        page: 1,
        take: 5,
        category_id: 0,
        show_only_selected: false,
        select_product: [],
        delete_product: [],
      },
      selectProduct: [],
      deleteProduct: [],
      form: {
        name: "",
        sort_order: 0,
        ah_id: "",
      },
      checkrequiredtype: {
        required: null,
      },
      image: {
        width: "",
        height: "",
      },
      selected: { C: "3PO" },
      subForm: {
        name: "",
        sort_order: 0,
        parent_id: 0,
        active_items: "",
        ah_id: 0,
        select_product: [],
        delete_product: [],
      },
      categoryLevel: 0,
      isMain: true,
      notUpdate: false,
    };
  },
  validations: {
    form: {
      name: { required },
    },
  },
  created: async function () {
    this.$bus.$emit("showLoading");
    this.getProductList();
    await this.getCategoryList();
    this.$bus.$emit("hideLoading");
  },
  methods: {
    clearField() {
      this.form = {
        name: "",
        sort_order: 0,
        ah_id: "",
      };
    },
    getProductList: async function () {
      this.isBusy = true;

      const { data } = await this.axios.post(
        "/Category/GetProduct/ProductCategory",
        this.filterGetProduct
      );

      if (data.result == 1) {
        this.productList = data.detail.data;
        this.rows = data.detail.count;
        this.isBusy = false;
      }
    },

    pagination(val) {
      this.filterGetProduct.page = val;
      this.getProductList();
    },
    hanndleChangePerpage(val) {
      this.filterGetProduct.take = val;
      this.getProductList();
    },
    handleSearchProduct(val) {
      this.filterGetProduct.search = val;
      this.getProductList();
    },
    getCategoryList: async function () {
      // this.isLoadingData = true;
      this.filterGetcategory.includeSubcategory = true;
      const { data } = await this.axios("/Category/GetProductCategory");

      if (data.result == 1) {
        this.items = data.detail.category;
        // this.isLoadingData = false;
      }
    },
    async createRoot() {
      this.isLoadingData = true;
      this.filterGetcategory.includeSubcategory = true;
      const { data } = await this.axios.post(
        "/Category/CreateProductCategory/Root",
        this.form
      );

      if (data.result == 1) {
        this.items = data.detail;
        this.isLoadingData = false;
      }
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.setDataImage(reader.result);
          }
        };
        img.src = await reader.result;
      };
    },
    async setDataImage(base64) {
      var url = await this.saveImagetoDb(base64, false);
      this.isLoadingImage = false;
      this.isDisable = false;
      this.showPreview = url;
      this.form.category.image_url = url;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    addParent(ref) {
      this.parentList.push(ref);
    },
    deleteImage(value) {
      this.form.category.image_url = null;
      this.showPreview = null;
    },
    async setFormMainCategory() {
      this.form = {
        name: "",
        sort_order: 0,
        ah_id: "",
      };
      this.isSubCategory = false;
      this.categoryLevel = 0;
    },
    async createMainCategory() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$error) return;
        this.form.ah_id = this.form.ah_id || 0;
        this.$bus.$emit("showLoading");
        const result = await this.axios.post(
          "/Category/CreateProductCategory/Root",
          this.form
        );
        this.$bus.$emit("hideLoading");
        this.$v.form.$reset();
        if (result.data.result == 1) {
          this.getCategoryList();
          this.successAlert();
        } else {
          this.errorAlert(result.data.message);
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },

    async createSubCategory(val) {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$error) return;
        let payload = { ...this.subForm, ...this.form };
        this.$bus.$emit("showLoading");
        this.$v.form.$reset();
        // payload.parent_id = val.id;
        // payload.ah_no = this.form.ah_id || 0;
        payload.ah_id = this.form.ah_id || 0;
        delete payload.delete_product;

        delete payload.id;

        const result = await this.axios.post(
          "/Category/CreateProductSubcategory",
          payload
        );
        this.$bus.$emit("hideLoading");
        if (result.data.result == 1) {
          this.getCategoryList();
          this.successAlert();
        } else {
          this.errorAlert(result.data.message);
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    async updateProductCategory() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$error) return;
        this.$bus.$emit("showLoading");
        let payload = { ...this.subForm, ...this.form };
        payload.ah_id = this.form.ah_id || 0;
        payload.delete_product = this.productFreeze.filter(
          (el) => !payload.select_product.includes(el)
        );

        this.$v.form.$reset();
        const result = await this.axios.put(
          "/Category/UpdateProductCategory",
          payload
        );

        this.$bus.$emit("hideLoading");
        if (result.data.result == 1) {
          this.successAlert();
          this.getCategoryList();
          this.$nextTick(() => {
            this.getProductList();
            this.$refs.ProductList.getProductInCategory(payload.ah_id);
          });
        } else {
          this.errorAlert(result.data.message);
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    getCategoryData(val, lvl, pVal) {
      this.notUpdate = false;
      this.isSubCategory = true;

      this.categoryLevel = lvl;

      this.activeItem = `level-${lvl}-id-${val.id}`;
      this.subForm = {
        name: "",
        sort_order: 0,
        parent_id: val.id,
        active_item: `level-${lvl}-id-${val.id}`,
        ah_id: 0,
        select_product: [],
      };
      this.form = {
        name: "",
        sort_order: 0,
        ah_id: "",
      };
      if (lvl == 4) this.form = val;

      this.$nextTick(() => {
        if (lvl == 4 || lvl == 3) {
          this.filterGetProduct.page = 1;
          this.$refs.ProductList.getProductInCategory(val.ah_id);
        }
      });
      this.$v.form.$reset();
    },
    getForUpdateCategoryData(val, lvl, pVal) {
      this.notUpdate = true;
      this.isSubCategory = true;

      this.categoryLevel = lvl;

      this.activeItem = `level-${lvl}-id-${val.id}`;
      this.subForm = {
        name: "",
        sort_order: 0,
        parent_id: val.id,
        active_item: `level-${lvl}-id-${val.id}`,
        ah_id: 0,
        select_product: [],
      };
      this.form = val;

      this.$nextTick(() => {
        if (lvl == 4) {
          this.filterGetProduct.page = 1;
          this.$refs.ProductList.getProductInCategory(val.ah_id);
        }
      });
      this.$v.form.$reset();
    },
    setProductID(items) {
      this.subForm.select_product = items;
    },
    async deleteCategory() {
      this.$bus.$emit("showLoading");
      const result = await this.axios.delete(
        `/Category/DeleteProductCategory/${this.form.ah_id}`
      );
      this.$bus.$emit("hideLoading");
      if (result.data.result == 1) {
        this.successAlert();
        this.setFormMainCategory();
        await this.getCategoryList();
      } else this.errorAlert(resutl.data.message);
    },
    async submit() {
      if (this.categoryLevel == 4 || this.notUpdate)
        return this.updateProductCategory();
      if (this.isSubCategory) {
        await this.createSubCategory();
      } else {
        await this.createMainCategory();
      }
      this.form = { name: "", sort_order: 0, ah_id: "" };
      this.subForm = {
        ...this.subForm,
        name: "",
        sort_order: 0,
        ah_id: 0,
        select_product: [],
        delete_product: [],
      };
      this.$v.form.$reset();
    },
  },
};
</script>

<style scoped>
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  box-shadow: 0px 5px 0px var(--theme-secondary-color),
    0px 5px 10px var(--theme-secondary-color);
  margin-left: var(--margin-spacing);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
.preview-box-category {
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
</style>
